.image-gallery-slide .image-gallery-description {
  bottom: 0px;
}

.image-gallery-slide .image-gallery-image {
  object-fit: contain;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  margin-top: 0;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 50px;
}
