* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Space Grotesk", sans-serif;
}

a {
  color: #ffffff;
  text-decoration: none;
}
